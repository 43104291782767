body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #040501;
  color: #d6dadf;
  overflow-x: hidden;
}
a {
  color: #95c12b;
  text-decoration: none;
}
a:hover {
  color: #95c12b;
  text-decoration: underline;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #95c12b;
}
.brand_logo {
  width: 180px;
}
.main_navbar {
  background-color: transparent !important;
  z-index: 10;
  transition: 0.2s all;
  padding: 15px 0;
}
.main_navbar.fixed_navbar {
  position: sticky;
  width: 100%;
  top: 0;
  background-color: #060606 !important;
  box-shadow: 0 0 10px 5px rgb(4 4 4 / 50%);
  z-index: 999;
  padding: 15px 0;
}
.navbar_right {
  display: flex;
  align-items: center;
}
.logout_btn {
  background: transparent;
  border: 2px solid transparent;
  color: #fff;
  font-size: 20px;
  margin-right: 15px;
  transition: 0.2s all;
  padding: 5px 20px;
  border-radius: 30px;
}
.logout_btn:hover {
  background: #021039;
  border: 2px solid #0b1c47;
}
.logout_btn img {
  margin-right: 10px;
}
.page_header {
  position: relative;
  padding: 120px 0 50px;
  background: url("../src/assets/images/banner_bg.png") no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 850px;
  margin-top: -130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page_header h1 {
  font-size: 50px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 50px;
  font-family: "Pattaya", sans-serif;
  text-shadow: 0 0 5px #000;
}
.page_header h5 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  color: #fff;
}
.page_header button.primary_btn {
  margin: 30px 0 0;
}
.page_header button.primary_btn a {
  color: #fff;
  text-decoration: none;
}
.page_header button.primary_btn:hover a {
  color: #a9d837;
}
.orange_txt {
  color: #a9d837 !important;
}
.banner_img {
  text-align: center;
}
.banner_img img {
  border-radius: 50px;
}
section {
  padding: 80px 0;
}
.main_title_small {
  font-size: 24px;
  font-weight: 400;
  color: #fff;
}
.main_title {
  font-size: 48px;
  font-weight: 400;
  color: #95c12b;
  margin-bottom: 30px;
  font-family: "Pattaya", sans-serif;
}
.inner_para {
  font-size: 18px;
  color: #fff;
}
.how_works_section h3 {
  color: #95c12b;
  font-size: 24px;
  font-weight: 600;
}
.how_works_section button.primary_btn {
  margin: 30px 0 0 !important;
}
.primary_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 30px;
  box-sizing: border-box;
  color: #fff;
  background: #111f3d;
  background-clip: padding-box;
  border: 2px solid #223359;
  border-radius: 50px;
  transition: 0.2s all;
  overflow: hidden;
}
.features_section .animated {
  height: 100%;
}
.features_box.primary_box {
  cursor: default;
  height: 100%;
  background: rgb(2, 3, 0);
  background: linear-gradient(
    130deg,
    rgba(2, 3, 0, 1) 3%,
    rgba(163, 79, 33, 1) 100%
  );
  border: 2px solid #5f2f14;
  z-index: 1;
}
.primary_box.features_box::before {
  content: "";
  width: 100%;
  height: 711px;
  position: absolute;
  top: -200px;
  right: -100px;
  transition: 0.2s all;
}
.primary_box.features_box p {
  max-width: 92%;
}
.features_box img {
  width: 96px;
  height: 96px;
}
.features_box_flex_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.features_box::before {
  background: url("../src/assets/images/feature_box_wave.png");
}
/* .features_box_02::before {
  background: url("../src/assets/images/icon_02.png");
}
.features_box_03::before {
  background: url("../src/assets/images/icon_03.png");
} */
.primary_box.features_box:hover::before {
  transform: scale(1.1);
}
.features_box h4 {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 400;
  color: #95c12b;
  max-width: 70%;
  margin-bottom: 20px;
  font-family: "Pattaya", sans-serif;
}
.features_box > div {
  margin-bottom: 20px;
}
.primary_box p {
  margin-bottom: 0;
}
.features_section [class*="col-"] {
  margin-bottom: 30px;
}
.cta_section_01 {
  background: #000 url("../src/assets/images/cta_01_bg.png") right center
    no-repeat;
  border: 2px solid #242424;
  padding: 50px;
  border-radius: 30px;
  position: relative;
  z-index: 1;
}
.cta_section_01 h2 {
  font-size: 44px;
  font-weight: 700;
  margin: 20px 0 0;
}
.register_section,
.how_works_section {
  background-color: #081125;
}
.how_register_div {
  width: 100%;
  padding: 40px;
  position: relative;
  margin-bottom: 60px;
  border: 2px solid #1d2d4f;
  border-radius: 30px;
}
.how_register_div::before {
  content: "";
  background: url("../src/assets/images/arrow_shape.png");
  width: 71px;
  height: 52px;
  position: absolute;
  bottom: -47px;
}
.how_register_div:nth-child(even)::before {
  right: 50px;
}
.how_register_div:last-child::before {
  display: none;
}
.how_register_panel {
  margin-top: 40px;
}
.how_register_div h2 {
  font-size: 34px;
  font-weight: 800;
  color: #95c12b;
  margin: 0 0 20px;
}
.how_register_div_content h4 {
  font-size: 24px;
  font-weight: 800;
  color: #95c12b;
  margin: 0 0 10px;
}
.how_register_div_content p,
.how_register_div_content ol li {
  font-size: 16px;
  color: #fff;
  margin-bottom: 0;
}
.how_register_div_content ol li {
  margin-bottom: 20px;
}
.cta_section_02 {
  background: #06051e url("../src/assets/images/cta_02_bg.png") center no-repeat;
  border: 2px solid #242424;
  padding: 50px;
  border-radius: 30px;
  min-height: 250px;
}
.plan_tab.nav-pills li button.nav-link {
  border: 2px solid #223359;
  background-color: #111f3d;
  color: #7a7e89;
  margin: 0 10px;
}
.plan_tab.nav-pills li button.nav-link.active {
  color: #fff;
}
.plan_income_table {
  border: 2px solid #0b1c47;
  border-radius: 0;
  background: #020f31;
  overflow: hidden;
  margin: 40px 0 0;
}
.plan_income_table th,
.plan_income_table td {
  border: 0 !important;
  vertical-align: middle;
}
.plan_income_table th {
  color: #0b162e;
  font-size: 18px;
  font-weight: 700;
}
.plan_income_table td {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.plan_income_table th:first-child,
.plan_income_table td:first-child {
  padding-left: 30px;
  width: 150px;
}
.plan_income_table th:last-child,
.plan_income_table td:last-child {
  padding-right: 30px;
}
.plan_income_table_div {
  border-radius: 10px;
}
.plan_income_table th {
  background-color: #95c12b;
}
.plan_income_table tbody tr {
  border: 1px solid #070f21;
  border-left: 0;
  border-right: 0;
}
.plan_income_table tbody tr:nth-child(even) {
  background-color: #152446;
}
.plan_income_table tbody tr:nth-child(odd) {
  background-color: #0e1a36;
}
.plan_income_table td h4 {
  font-size: 22px;
  font-weight: 700;
  margin: 3px 0;
}
#faq_accodian {
  margin-top: 50px;
}
#faq_accodian .accordion-header button {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: none;
  font-size: 18px;
  font-weight: 400;
  color: #95c12b;
  text-decoration: none;
  background: transparent;
}
#faq_accodian .accordion-header,
#faq_accodian .accordion-body {
  background-color: transparent;
}
#faq_accodian .accordion-item {
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  color: #fff;
  background: #152446;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 15px;
  transition: 0.2s all;
  margin-bottom: 30px;
  overflow: unset;
}
#faq_accodian .accordion-body {
  padding-top: 0;
}
#faq_accodian .accordion-body p {
  color: #a2b1cf;
  font-size: 18px;
  margin-bottom: 0;
  cursor: default;
}
/* #faq_accodian .accordion-button::after {
  filter: invert(1) contrast(0) brightness(1);
} */
.footer {
  background-color: #020200;
  padding: 30px 0 20px;
}
.footer_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_panel_left p {
  margin-bottom: 0;
  font-size: 16px;
}
.footer_panel_left a {
  font-size: 18px;
  color: #95c12b;
  white-space: normal;
  word-break: break-all;
}
.footer_social_links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer_social_links li {
  margin: 0 0 0 15px;
}
.footer_social_links li a {
  border: 0 solid #101010;
  background: #101010;
  color: #454545;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-decoration: none;
  transition: 0.2s all;
}
.footer_social_links li a:hover {
  background: #95c12b;
  border: 1px solid #a9d837;
  color: #000;
  box-shadow: 0 0 5px 2px rgba(148, 193, 43, 0.7);
}

.primary_btn::before {
  background: rgb(44, 59, 8);
  background: linear-gradient(
    90deg,
    rgba(44, 59, 8, 1) 0%,
    rgba(149, 193, 43, 1) 100%
  );
  border-radius: 0;
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  right: -1px;
  top: 0;
  -webkit-transform: none;
  transform: none;
  transition: all 0.3s ease-in-out;
  will-change: transform;
  z-index: -1;
}
.primary_btn:hover,
.primary_btn:active,
.primary_btn.active {
  color: #a9d837;
  background: transparent;
  border-color: #a9d837;
}
.primary_btn:hover::before,
.primary_btn:active::before,
.primary_btn.active::before {
  border-radius: 30px;
  transform: translate3d(-101%, 0, 0);
}
.primary_btn {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 1.715;
  border: 1px solid #a9d837 !important;
  border-radius: 30px;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  transition: 250ms all ease-in-out;
  color: #fff;
  text-decoration: none !important;
  background: transparent;
  z-index: 1;
  box-shadow: inset 0 0 5px 2px rgba(3, 4, 1, 0.7) !important;
  margin: 30px auto 0;
}
.primary_btn .wallet_icon {
  filter: brightness(0) invert(1);
  transition: 0.02s all ease-in-out;
  margin-right: 10px;
  height: 26px;
}
.primary_btn:hover .wallet_icon {
  filter: unset;
}
.navbar_right .primary_btn {
  margin: 0;
}
.d_d_none {
  display: none !important;
}
.inner_para a {
  word-break: break-all;
}

/* Inner Pages */
.inner_wrapper {
  min-height: 80vh;
  padding: 80px 0;
  position: relative;
}
.inner_wrapper::before {
  content: "";
  background-image: url("../src/assets/images/banner_bg.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: top center;
  position: absolute;
  top: -100px;
  background-color: #000;
  z-index: -1;
}
.dashboard_panel {
  background: #0b162e;
  border-radius: 20px;
  padding: 30px;
}
.dashboard_panel.join_now_panel {
  min-height: 92vh;
  background: black;
  padding: 30px;
}
.title_flex {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 30px;
}
.inner_title {
  font-size: 48px;
  font-weight: 700;
  color: #95c12b;
  margin-bottom: 0;
  font-family: 'Pattaya', sans-serif;
}
.wallet_div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wallet_div label {
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  white-space: nowrap;
  margin: 0px 5px 0 0;
}
.primary_inp {
  border: 1px solid #223359;
  background: #0b162e;
  color: #fff !important;
  font-size: 16px;
  border-radius: 10px;
  padding: 12px;
}
.primary_inp:focus {
  border: 1px solid #223359;
  background: #0b162e;
  box-shadow: none !important;
}
.primary_inp::placeholder {
  color: #909090;
}
.inp_grp {
  position: relative;
}
.inp_append {
  position: absolute;
  right: 12px;
  top: 10px;
  cursor: pointer;
}
.wallet_div input.primary_inp {
  width: 350px;
  background: transparent;
  border: none;
  box-shadow: none;
  color: #617398 !important;
  font-size: 20px;
  padding: 2px;
}
button.round_btn {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
img.copy_icon.wallet_icon {
  margin-right: 0;
}
.wallet_div .inp_grp {
  display: flex;
  align-items: center;
}
.last_login_details {
  font-size: 16px;
  font-weight: 200;
}
.dash_box_left {
  padding: 10px 0px;
  border-bottom: 1px solid #031542;
}
.dash_box_left:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.dash_box_left:first-child {
  padding-bottom: 20px;
}
.dash_box_left h2 {
  font-size: 24px;
  font-weight: 700;
}
.dash_box_left h5 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.dash_box_left h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
}
.dash_box_left h3 span {
  color: #e8d7f8;
  font-size: 20px;
}
.dash_box_left p {
  color: #e8d7f8;
  font-size: 18px;
  margin-bottom: 0;
}
.dash_box_left h4 {
  color: #e8d7f8;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.dashbox_middle_top {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;
}
.dashbox_middle_top h2 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}
.dashbox_middle_top img.img-fluid {
  min-width: 60px;
}
.dashbox_middle_top h4 {
  font-size: 18px;
  font-weight: 400;
  color: #617398;
}
.dashbox_middle_top > div {
  display: flex;
  align-items: center;
  cursor: default;
}
.dashbox_middle_bottom h2 {
  font-size: 20px;
  font-weight: 700;
  color: #95c12b;
  margin-bottom: 15px;
  padding-bottom: 5px;
}
.dashbox_middle_bottom h5 {
  font-size: 24px;
  font-weight: 500;
}
.dashboard_top_wrap [class*="col-"] {
  margin-bottom: 30px;
}
.dashbox_middle_bottom ul.footer_social_links li a {
  width: 28px;
  height: 28px;
  font-size: 14px;
}
.dashbox_middle_bottom ul.footer_social_links {
  justify-content: center;
  margin-top: 15px;
}
.dashbox_middle_bottom ul.footer_social_links li {
  margin: 0 10px 0px 0;
}
.dashboard_top_wrap > [class*="col-"]:last-child {
  margin-bottom: 0;
}
.search_div {
  width: 50%;
  margin: 50px auto 0;
}
.search_div button {
  background: #95c12b;
  border: none;
  font-size: 18px;
  font-weight: 500;
  color: #0b162e;
  position: absolute;
  top: 1px;
  right: 0;
  border-radius: 0 10px 10px 0;
  padding: 6px 20px;
}
.plan_wrapper {
  /* display: flex;
  flex-flow: wrap;
  justify-content: center; */
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.buy_item {
  background-color: #0b162e;
  padding: 20px 10px 20px;
  border-radius: 20px;
  position: relative;
  /* width: 200px; */
  margin: 50px 0 20px;
  cursor: default;
}
.buy_item h4 {
  font-size: 16px;
  font-weight: 500;
  color: #95c12b;
}
.buy_item h5 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.buy_item .primary_btn {
  padding: 4px 12px;
  font-size: 16px;
  margin: 15px auto 0;
  width: auto !important;
  border: 2px solid transparent;
}
.buy_item .primary_btn:hover {
  color: #95c12b;
  background-color: #000000 !important;
  box-shadow: 0 0 5px 2px rgba(148, 193, 43, 0.7);
}
.dashbox_middle_bottom .primary_btn {
  width: 100%;
  justify-content: center;
}
.buy_count {
  /* background: #95c12b;
  border: 1px solid #ffd47e;
  box-shadow: 0 0 5px 2px rgba(148, 193, 43, 0.7);
  position: relative;
  border-radius: 50%;
  margin: -75px auto 15px;
  width: 130px;
  height: 130px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.buy_count p {
  font-size: 14px;
  color: #95c12b;
  font-weight: 700;
}
.buy_count h2 {
  font-size: 36px;
  color: #95c12b;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  line-height: 40px;
}
.buy_count h3 {
  font-size: 24px;
  color: #95c12b;
  font-weight: 700;
  margin: 0;
}
.buy_data {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
.buy_data_item {
  display: flex;
  align-items: center;
}
.buy_data_count p {
  font-size: 18px;
  color: #fff;
  line-height: 26px;
  margin-left: 5px;
}
.chance_panel h2 {
  font-size: 24px;
  text-transform: uppercase;
}
.buy_item.buy_item_disabled {
  opacity: 0.6;
  pointer-events: none;
  user-select: none;
}
/* .buy_item:hover .buy_count {
  animation-name: hvr-pulse;
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
} */
.primary_box.dash_box {
  padding: 20px 30px;
}
@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
/* Connect Wallet */
.primary_modal .modal-content {
  background: linear-gradient( -47deg, rgba(44, 59, 8, 1) 0%, rgba(149, 193, 43, 1) 100% );
}
.primary_modal .modal-header {
  border-bottom: 1px solid #a9d837;
  padding: 20px 24px 15px;
}
.connect_wallet_button {
  border: 2px solid #a9d837;
  background-color: transparent;
  color: #111111;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
}
.connect_wallet_button:hover {
  border-color: #95c12b;
}
.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}
.connect_wallet_button img {
  margin-right: 15px;
}
.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}
.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  background: transparent url("../src/assets/images/close_icon.png") no-repeat;
  box-shadow: none !important;
}
.primary_modal a {
  text-decoration: none;
}
.node circle {
  fill: #95c12b !important;
  stroke: #95c12b !important;
  stroke-width: 1.5px !important;
  background: url("../src/assets/images/tree_icon.png");
  width: 13px;
  height: 21px;
}
.node text {
  font-size: 11px !important;
  background-color: #444 !important;
  fill: #fff !important;
}
.node {
  cursor: pointer;
}
path.link {
  stroke: #95c12b !important;
  stroke-width: 1.5px !important;
}
.dash_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0px;
  cursor: pointer;
  margin-right: 15px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
  color: #95c12b;
  box-shadow: 0 0 0 3px #95c12b;
  transition: color 0.3s;
}
.dash_icon:after {
  top: -2px;
  left: -2px;
  padding: 2px;
  z-index: -1;
  background: #95c12b;
  transition: transform 0.2s, opacity 0.2s;
}
.dash_icon:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.dashbox_middle_top > div:hover .dash_icon::after {
  transform: scale(0.85);
}
.address_btn {
  display: inline-block;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  width: 200px;
}
ul.walletdrop {
  background: #0c0c0c;
  border: 1px solid #242424;
}
ul.walletdrop li a {
  color: #eee;
  text-decoration: none;
}
ul.walletdrop li a:hover {
  background-color: #8fb929;
  color: #fff;
}
.join_now_panel .primary_box.dash_box {
  height: 100%;
}
.join_now_panel .row [class*="col-"] {
  margin-bottom: 30px;
}
.timer_panel {
  display: flex;
  justify-content: center;
}
span.timer_label {
  display: block;
  color: #dfdfdf;
  font-size: 18px;
}
.timer_panel > span {
  margin: 0 8px;
  min-width: 60px;
}
span.timer_time {
  color: #95c12b;
  font-size: 36px;
  font-weight: 700;
}
span.timer_dots {
  margin: 0;
  line-height: 46px;
  color: #95c12b;
  font-size: 36px;
  min-width: auto;
}

/* Media Queries */
@media screen and (max-width: 1399px) {
  .slider .slick-prev {
    left: 224px !important;
  }
  .slider .slick-next {
    right: 224px !important;
  }
}
@media screen and (max-width: 1199px) {
  .dashbox_middle_top h2 {
    font-size: 32px;
  }
  .cta_section_01 {
    background-size: contain;
  }
  .plan_wrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .dash_icon {
    width: 90px;
    height: 90px;
  }

  .slider .dashbox_middle_bottom h5 {
    margin-bottom: 10px !important;
  }
  .dashbox_middle_top > div {
    flex-wrap: wrap;
  }
  .slider .slick-prev {
    left: -10px !important;
  }
  .slider .slick-next {
    right: -10px !important;
  }
}
@media screen and (max-width: 991px) {
  .wallet_div {
    display: block;
  }
  .inner_title {
    margin-bottom: 15px;
  }
  .wallet_div .inp_grp {
    justify-content: center;
  }
  .wallet_div label {
    margin: 0 !important;
  }
  .footer_panel {
    display: block;
    text-align: center;
  }
  .footer_social_links {
    justify-content: center;
    margin-top: 15px;
  }
  .page_header h1 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 20px;
  }
  .page_header h5 {
    max-width: 80%;
    text-align: center;
    margin: 0 auto 20px;
  }
  .d_m_none {
    display: none !important;
  }
  .d_d_none {
    display: block !important;
  }
  .cta_section_01 {
    background-size: cover;
  }
  .cta_section_01::before {
    content: "";
    background: #000;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 30px;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .page_header {
    height: auto;
  }
  .dashbox_middle_top {
    flex-wrap: wrap;
  }
  .dashbox_middle_top > div {
    margin-bottom: 15px;
  }
  .buy_count h2,
  .slider .dashbox_middle_bottom h2 {
    font-size: 28px !important;
    line-height: 32px;
  }
  .buy_count h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  #box5 {
    overflow: auto;
  }
  .dashbox_middle_top {
    display: block;
  }
  .dashbox_middle_top > div {
    margin-bottom: 30px;
  }
  .dashbox_middle_top > div:last-child {
    margin-bottom: 0;
  }
  .dashbox_middle_top h2 {
    margin-bottom: 0;
  }
  .search_div {
    width: 100%;
  }
  .footer_panel_left a {
    font-size: 20px;
  }
  button.logout_btn {
    margin-bottom: 15px;
  }
  /* .main_navbar {
    background-color: #000e32 !important;
    box-shadow: 0 0 10px 5px rgb(4 4 4 / 50%);
  } */
  .page_header h1 {
    font-size: 36px;
  }
  .page_header h5 {
    max-width: 100%;
    font-size: 24px;
  }
  .main_title {
    font-size: 36px;
  }
  .faq_section .text-right {
    text-align: left !important;
  }
  .how_register_div h2 {
    font-size: 28px;
  }
  .how_register_div_content h4 {
    font-size: 20px;
  }
  .cta_section_02 {
    background-size: cover;
  }
  .plan_wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .navbar_right {
    margin: 20px auto 10px;
    justify-content: center;
  }
  .main_navbar .container {
    justify-content: center !important;
  }
  .last_login_details {
    margin: 15px 0 0;
  }
  .inner_title {
    font-size: 36px;
  }
  .title_flex {
    flex-direction: column;
    justify-content: center;
  }
  .slider .dashbox_middle_bottom h2 {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 575px) {
  .dashbox_middle_bottom h2 {
    font-size: 18px;
  }
  .inner_para {
    font-size: 18px;
  }
  .main_title_small {
    font-size: 20px;
  }
  .main_title {
    font-size: 28px;
  }
  .main_navbar .container-fluid {
    display: block;
    text-align: center;
  }
  .primary_btn {
    padding: 12px 25px;
    font-size: 16px;
  }
  .plan_wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .wallet_div input.primary_inp {
    width: auto;
    text-overflow: ellipsis;
  }
  .dash_icon {
    width: 70px;
    height: 70px;
    margin: 0 auto 15px;
  }
  .dashbox_middle_top > div {
    display: block;
    text-align: center;
  }
  .dashbox_middle_top h2 {
    text-align: center;
    font-size: 28px;
  }
  .dash_icon img {
    width: 32px;
  }
}
@media screen and (max-width: 479px) {
  .navbar-brand img {
    width: 220px;
  }
}
body {
  background-color: #000;
}

.primary_btn {
  background: rgb(44, 59, 8);
  background: linear-gradient(
    -47deg,
    rgba(44, 59, 8, 1) 0%,
    rgba(149, 193, 43, 1) 100%
  );
}
.wallet_div label {
  font-size: 15px;
}
.wallet_div input.primary_inp {
  color: #95c12b !important;
  width: 260px;
  font-size: 15px;
}
.title_flex .primary_btn {
  box-shadow: none !important;
  border: 2px solid #1d1d1d !important;
}
button.round_btn {
  width: 30px;
  height: 31px;
  background: transparent;
}
.dashboard_panel {
  background: #000000;
  border: 2px solid #242424;
}

.dashbox_middle_top {
  display: block;
  padding: 0;
  position: relative;
}
.dashbox_middle_top > div {
  justify-content: space-between;
}
.dashbox_middle_top h2 {
  font-size: 30px;
  margin-bottom: 0;
}
.dashbox_middle_top h4 {
  font-size: 16px;
  color: #9f9f9f;
  padding-right: 13px;
}
.dashbox_middle_bottom h2 {
  font-size: 24px;
}
.primary_box {
  background: transparent;
  border: 2px solid #242424;
}
.buy_count h2 {
  font-size: 30px;
  color: #fff;
}
.buy_count h3 {
  font-size: 20px;
  color: #9f9f9f;
  font-weight: 400;
}
.buy_count p {
  font-size: 16px;
  color: #95c12b;
  font-weight: 600;
  text-align: center;
}
.buy_item_inner {
  position: relative;
}
.buy_count {
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
}
.buy_item {
  background: transparent;
  border: 2px solid #242424;
  margin: 0px 0 30px;
  position: relative;
  padding: 15px 10px 20px;
}
.buy_item h4 {
  color: #7f7f7f;
  margin-bottom: 4px;
}
.buy_item::before {
  content: "";
  position: absolute;
  background: url("../src/assets/images/buyellipse.png") no-repeat;
  width: 108px;
  height: 106px;
  top: 0;
  right: 0;
  border-radius: 19px;
}
.buy_item .primary_btn {
  border: 0;
  box-shadow: none;
  margin-top: 25px;
  margin-bottom: -35px;
}
.tree .dashbox_middle_bottom {
  padding: 20px;
}

.plan_income_table tbody tr:nth-child(odd) {
  background-color: #0b0b0b;
}
.plan_income_table tbody tr:nth-child(even) {
  background-color: #111111;
}
.plan_income_table {
  border: 0;
}

.footer {
  background-color: #020200;
}
/*.footer_social_links li a {
  background: #000000;
  border: 0px solid #152446;
}*/

.tree {
  padding: 0 !important;
}
.tree .wholebox .boxctn h5 {
  padding: 20px;
  background-color: #0b0b0b;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
}
.tree .boxctn h5.collapsed {
  background-image: url("../src/assets/images/rightarw.png");
  background-repeat: no-repeat;
  background-position: 96% center;
}
.tree .accordion .boxctn h5:not(.collapsed) {
  background-image: url("../src/assets/images/downarw.png");
  background-repeat: no-repeat;
  background-position: 96% center;
}

.leftbox .primary_box.dash_box::before {
  content: "";
  position: absolute;
  background-image: url("../src/assets/images/boxelipse1.png");
  width: 141px;
  height: 110px;
  top: 0;
  right: 0;
  border-radius: 0 19px;
}

.dashboard_top_wrap .slider [class*="col-"] {
  margin-bottom: 0;
}
.slider .dashbox_middle_bottom h5 {
  font-size: 16px;
  color: #9f9f9f;
  font-weight: 400;
  margin-bottom: 0;
  min-height: 42px;
}
.slider .dashbox_middle_bottom h2 {
  font-size: 30px;
  color: #fff;
}
.slider .dashbox_middle_bottom .primary_btn {
  width: unset;
  margin: 0;
  padding: 10px 30px;
}
.slider .dashbox_middle_bottom span {
  color: #9f9f9f;
}
.slider .box {
  background-color: #0c0c0c;
  padding: 20px;
  border-radius: 20px;
  margin-top: 35px;
}

.slider .slick-prev::before,
.slider .slick-next::before {
  display: none;
}
.slider.slick-prev:before,
.slider.slick-next:before {
  display: none;
}
.slider.slick-prev {
  width: 22px;
  height: 18px;
  background: url("../src/assets/images/slideleftarw.png") !important;
}
.slider.slick-next {
  width: 22px;
  height: 18px;
  background: url("../src/assets/images/sliderightarw.png") !important;
}

.slider .slick-slide {
  outline: none;
}

.slider .slick-next:before,
.slider .slick-prev:before {
  display: none;
}

.slider .slick-prev {
  width: 22px;
  height: 18px;
  background: url("../src/assets/images/slideleftarw.png") no-repeat center !important;
  z-index: 1;
  left: 270px;
  top: 50%;
  bottom: 0;
  background-color: #79a70c !important;
  border-radius: 50%;
  padding: 20px;
  border: 2px solid transparent;
}

.slider .slick-prev:hover,
.slider .slick-prev:focus {
  background: url("../src/assets/images/slideleftarw.png") no-repeat center !important;
  width: 22px;
  height: 18px;
  border: 2px solid #a9d837;
  box-shadow: 0 0 5px 2px rgba(148, 193, 43, 0.7);
  background-color: #95c12b !important;
}

.slider .slick-next {
  width: 22px;
  height: 18px;
  background: url("../src/assets/images/sliderightarw.png") no-repeat center !important;
  right: 270px;
  top: 50%;
  bottom: 0;
  background-color: #79a70c !important;
  border-radius: 50%;
  padding: 20px;
  border: 2px solid transparent;
}

.slider .slick-next:hover,
.slider .slick-next:focus {
  background: url("../src/assets/images/sliderightarw.png") no-repeat center !important;
  width: 22px;
  height: 18px;
  border: 2px solid #a9d837;
  box-shadow: 0 0 5px 2px rgba(148, 193, 43, 0.7);
  background-color: #95c12b !important;
}
.slider .slidebox {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0c0c0c;
  border-radius: 5px;
  padding: 15px;
  margin-top: 20px;
  opacity: 0.4;

  box-shadow: 0 0 7px 2px rgb(21 19 19 / 51%);
}
.slider .slick-center .slidebox {
  transform: scale(1);
  position: relative;
  border: 2px solid #95c12b;
  border-radius: 5px;
  margin-top: 0;
  padding: 30px;
  opacity: 1;
  box-shadow: none;
}

.slider .slidebox h5 {
  margin-bottom: 10px;
}
.slider .slidebox img {
  margin-bottom: 10px;
}

.dashboard_panel {
  background: linear-gradient(to bottom, #242424, black, black);
  border: 0px solid transparent;
  padding: 2px;
  border-radius: 19px;
}
.dasbox {
  border-radius: 20px;
  padding: 30px;
  background: #000000;
}

.dashboard_panel.join_now_panel .title_flex {
  display: block;
}

.join_now_panel .primary_inp {
  border: 1px solid #242424;
  background: #000000;
}

#faq_accodian .accordion-item {
  background: #0b0b0b;
  border: 1px solid #242424;
}
#faq_accodian .accordion-body p {
  color: #ffffff;
}
#faq_accodian .accordion-button.collapsed::after {
  filter: invert(1) contrast(1) brightness(1);
}
#faq_accodian .accordion-button::after {
  filter: invert(0) contrast(1) brightness(30);
}
.how_works_section {
  background-color: #0c0c0c;
}

.plan_tab.nav-pills li button.nav-link {
  border: 2px solid #242424;
  background-color: #0b0b0b;
  padding: 10px 30px;
}
.plan_tab.nav-pills li button.nav-link.active {
  color: #000;
  background-color: #95c12b;
}
.register_section {
  background: #000000;
}
.how_register_div {
  border: 2px solid #242424;
}

.how_register_div::before {
  width: 66px;
  height: 46px;
}
.round_btn.primary_btn::before {
  background: transparent;
}
.primary_box.features_box::before {
  z-index: -1;
}
@media screen and (max-width: 400px) {
  .spacepad {
    padding: 20px 15px !important;
  }
}
